.boton {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375em 0.75rem;
    font-size: 1rem;
    /* border-radius: 0.25rem; */
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease-in-out;
}

button:focus:not(:focus-visible), 
.boton-check:focus + .boton, 
.boton:focus, .boton-cart:focus {
    outline: 0;
}

.boton-verde {
    color: #fff;
    background-color: $verdePrimario;
    border-color: $verdePrimario;
}

.boton-verde-outline {
    color: $verdePrimario;
    background-color: #ffffff;
    border-color: $verdePrimario;
}

.boton-rosa {
    color: #fff;
    background-color: $rosaPrimario;
    border-color: $rosaPrimario;
}

.boton-rosa-outline {
    color: $rosaPrimario;
    background-color: #ffffff;
    border-color: $rosaPrimario;
}

.boton-rosa:hover, .boton-rosa-outline:hover {
    color: #fff;
    background-color: $rosaHover;
    border-color: $rosaHover;
}

.boton-cart {
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 0;
    box-sizing: border-box;
}

.boton-cart:hover {
    background-color: $rosaHover;
    border: 1px solid #f0eded;
}

.boton-cart:focus {
    background-color: $rosaHover;
    border: 1px solid #ffffff;
    border-radius: 0;
    box-sizing: border-box;
}

.boton-check:focus + .boton-verde, .boton-verde:focus {
    color: #fff;
    background-color: #2f818a;
    border-color: $verdeHover;
    box-shadow: 0 0 0 0.25rem rgba(27, 32, 39, 0.1);
}

.boton-verde:hover {
    color: #fff;
    background-color: #2f818a;
    border-color: $verdeHover;
}

