.catalog {
  /* padding: 80px 0; */

}
.cat-img>img {
    width: 100px;
    height: 100px;
  }
.instructions {
  /* font-size: 1rem; */
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(248, 248, 248);

    .cell {
      margin: 0;
      padding: 0;

      &> h3 {
        text-align: center;
      }

      &> p {
        display: block;
        margin: 0;
        padding: 8px;
        line-height: 1.25em;
        font-size: 1.05em;
      }
    }
}

#whyus {
    padding:80px 0;
    text-align: center;
    background-color: rgb(248, 248, 248);
  }
  
  .iconos {
    width: 100px;
  }

.product-home{
  text-decoration: none;
}

.prod-container {
  transition: all .2s ease-in-out;
  padding: 20px;

  .card {
    border: 0;

    &>img {
      border: none;
      border-radius: 0;
    }

    &-title {
      padding-top: 10px;
      font-size: 1em;
      color: #332d2d;
    }

    &-flags {
      margin: 0 0 5px 0;

      .lang-flag {
        width: 12%;
        margin-right: 2%;
        border: .5px solid rgb(176, 176, 176);

        &:last-child{
          margin-right: 0;
        }
      }
    }
  }

  &:hover {
    padding: 10px;
  }
}



@media (max-width: 768px) {
  .instructions .cell > p {
    font-size: 0.8em;
    line-height: 1em;
  }

  /* .card {
    padding-bottom: 30px;
  } */
}

