footer {
    color: #fff;
    background-color: #FB6869;
    z-index: 1000;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        & >li { 
            margin-left: 15px;
        }
          
    }

    .footer_nav {
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-size: .8rem;
      /* letter-spacing: .08rem; */
  
      .cards {
        width: 20px;
        margin-right: 5px;
        
      }
    }

    .social-share i{
      margin-right: 5px;
    }

    a {
        color: #fff;
        text-decoration: none;
        text-transform: capitalize;

        &:hover {
            color: #fff;
        }

    }
}

.bi{color: white; transition: all .2s ease-in-out; font-size: 1rem;} 
  
.bi-facebook:hover, .bi-facebook:visited {
  color: #49649C
}
.bi-pinterest:hover, .bi-pinterest:visited{
  color: #BA0E23
}
.bi-instagram:hover, .bi-instagram:visited {
  color: #DD326F
}
.bi-youtube:hover, .bi-youtube:visited {
  color: #FC0D1C
}


.t-right {
  text-align: right
}

 
 @media (max-width: 768px){
    .t-right, #form-rate p {
    text-align: center
  }
}
@media (min-width: 769px) and (max-width: 960px){
  .t-right {
  text-align: left;
}
}

  
  #copy, #copy a {
    font-size: 12px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: rgba(255, 243, 243, 0.6);
  }

  #copy a {
    text-decoration: none;
  }