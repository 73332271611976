.slider-text {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 5% 10%;
    color: #fff;
    text-align: left;

    .innerText {

        width: 60%;
        margin-left: 40%;

        h3 {
            font-size:2rem;
        }

        p {
            font-size: 1.2rem;
        }
    }
}

@media (max-width: 992px) {
    .slider-text {
        padding: 20px;

        .innerText {

            width: 60%;
            margin-left: 40%;
    
            h3 {
                font-size:1.2rem;
            }
    
            p {
                font-size: .9rem;
            }
        }
    }
}