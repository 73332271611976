.cart-item-img {
    max-width: 50px;
    max-height: 50px;
}

.delete-prod>.bi {
    color: $rosaPrimario;
    font-size: 1.4rem;
}

.delete-prod>i:hover {
    cursor: pointer;
}

/* .offcanvas-end {
    width: 30%;
} */

.total {
    text-align: right;
}

.badge {
    position: relative;
    top: -5px;
    left: -15px;
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 1em;
    font-weight: 700;
    line-height: 1;
    color: white;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}
