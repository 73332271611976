$rosaPrimario: #fb6969;
$verdePrimario: #33949B;

$rosaHover: #f75555;
$verdeHover: #74b8c1;


@import "~bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css?family=Inter');
@import url('https://fonts.googleapis.com/css2?family=Pangolin&display=swap');
/*
@font-face {
   font-family: "Rrr";
  src: url("../fonts/Rrr.eot");
  src: local("☺"), url("../fonts/Rrr.woff") format("woff"),
    url("../fonts/Rrr.ttf") format("truetype"),
    url("../fonts/Rrr.svg") format("svg");
  font-weight: normal;
  font-style: normal; 

}*/

@import "./navbar";
@import "./buttons";
@import "./forms";
@import "./index";
@import "./slider";
@import "./cart";
@import "./stepper";
@import "./footer";

body {
    width: 100%;
    height: 100%;
    //font-family: Arial Narrow, Arial, sans-serif;
    font-family: "Inter", sans-serif;
    color: #666;
    background-color: #fff;
    font-size: 16px;
    line-height: 1.6em;
    font-weight: 400;
}

p {
  font-size:16px;
}

.active:before {
  color: red;
}

.terms {
  color: #666;
  text-decoration: none;
}

/* main, section {
  font-family: Arial Narrow, Arial, sans-serif;
} */

main h2, section h2 { text-transform: uppercase; text-align: center; }

@media (min-width: 980px) {
  .minimal-height { min-height: 65vh;}
}

.triptich {
  gap: 4px;

  &>img {
    padding: .1rem;
  }
}

.note {
  font-size: 1em;
  color: #fb6969;
  padding: .5em .7em;
  border: 1px solid #fb6969;
}

.testimonials {
  width: 70%;
}

.testimonial-header {
  margin: 0;
  padding: 8px;
  text-align: center;
  text-transform: uppercase;
}

@media (max-width: 980px) {
  .testimonials { width: 90vw;}
}

.pulsing-heart {
  height: 4vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .pulsing-heart {
    animation: pulsing-heart-spin infinite 1.2s ease-in-out;
  }
}

@keyframes pulsing-heart-spin {
  from {
    transform: scale(.4, .5);
  }
  to {
    transform: rotate(20deg) scale(1.5, 1.4);
  }
}

.rotating-star {
  height: 4vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .rotating-star {
    animation: rotate-star infinite 3s ease-in-out;
  }
}

@keyframes rotate-star {
  from {
    transform: rotate(0deg) scale(.4);
  }
  to {
    transform: rotate(360deg) scale(1.4);
  }
}



