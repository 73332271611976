.navbar {
    flex-direction: row;
    margin: 0;
    padding: 0;
    background-color: $rosaPrimario;
    border-bottom: 0px;

    .navbar-nav {
        width: 100%;
    }

    .container {
        display: flex;
        color: #fff;
    }

    .logo {
        margin-top: 0px;
        margin-bottom: 0px;
        max-height: 55px
    }

    .navbar-brand .nav-link {
        padding: 0 auto;
    }

    .navbar-nav .nav-link, .navbar-nav .nav-link.active, .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:focus {
        color: #fff;
    }  
    
    .opciones-centrado {
        align-items: center;
    }
}


.nav-item {
    text-transform: uppercase;

    a,.nav-link, .active{
        color: #fff;
    }

    a:hover, .bi-question-circle-fill:hover {
        color: #e8e8e8;
    }

    &>.active {
        font-weight: bold;
    }

    .dropdown-menu {
        background-color: #ffffff;
        border: none;
        border-radius: 0;

        .dropdown-item {
            color: #383535;
        }

        .dropdown-item.active, .dropdown-item:active, .dropdown-item:hover {
            color: #040404;
            text-decoration: none;
            background-color: #fefefe;
          }

        .dropdown-item:hover {
            color: #272626;
        }
    }
}



@media (max-width: 992px) {
    .logo {
        max-width: 70%;

        &>img{padding-right: 10px;}
    }

    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;

        .container {
            display: flex;
            flex-direction: column;
            color: #fff;

            .main-menu {
                flex-direction: column;
            }
        }

        .opciones-centrado {
            align-items: normal;
        }
    }

    .nav-item .dropdown-menu {
        background-color: $rosaPrimario;
        border: none;
        border-radius: 0;

        .dropdown-item:hover {
            color: #ffffff;
            background-color: $rosaPrimario;
        }
    }

    
}

.navbar-toggler {
    background-color: #fff;
    margin: .5rem;


    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
        border: none;
        box-shadow: none;
    }
} 

