.stepper-wrapper {
    // font-family: Arial;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    color: white;
  
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .stepper-item>small{
    color: $verdePrimario;
  }
  
  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 25px;
    left: -50%;
    z-index: 2;
  }
  
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 25px;
    left: 50%;
    z-index: 2;
  }
  
  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $rosaPrimario;
    margin-bottom: 6px;
  }
  
  .stepper-item.active {
    color: $verdePrimario;
    font-weight: bold;
  }

  .stepper-item.active .step-counter{
    background-color: #fff;
    border: 2px solid $verdePrimario;
  }
  
  .stepper-item.completed .step-counter {
    background-color: $verdePrimario;
  }
  
  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid $verdePrimario;
    width: 100%;
    top: 25px;
    left: 50%;
    z-index: 3;
  }
  
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
  