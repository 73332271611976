input, textarea, .form-control, .form-select {
    border-radius: 0;
    outline:none;

    &:hover, &:active {
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0);
    }

    &:focus, &:focus-visible {
        outline: none;
        border: .3px solid #ebebeb;
        box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0);
    }
  }

.form-group{ margin-bottom: 20px;}

.l-rounded {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    justify-content: flex-start;
    flex-direction: column;
    margin-right: 30px;
}

.color-label {
    display: flex;
    position: relative;
    cursor: pointer;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    margin-right: 30px;
}

.hidden {
    display: none;
}

.l-rounded, .rounded, .rounded>img, .color-rounded {
    border-radius: 50%;
    height: 60px;
    width: 60px;
}

.rounded>img, .color-rounded {
    border: none;
    box-shadow: 0 0 5px #e2e2e2; 
}

input[type="radio"]:checked ~ .rounded>img,
input[type="radio"]:checked ~ .color-rounded
{
    border: 6px solid $verdePrimario;
}

.bordered {
    border-bottom: 1px solid #ccc;
}

button[type="submit"] {
    padding: 8px 14px;
}

.form-control::placeholder {
    color: #cdcdcd;
    
}

.block-form{
    padding: 15px;
    background-color: #f8f7f7;
    height: auto;
}

.stripe-header{
    background-color: #0c1c30;
    padding: 20px;
}

.error-messages{
    display: flex;
    justify-content: space-between;
}



